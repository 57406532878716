import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Link } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import MaterialMovers from "./MaterialMovers";
import CupOJoe from "./CupOJoe";

import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";

export type BlogLandingStaticContent = {
	title: string;
	subTitle: string[];
	subTitleLogo: string;
	loadMore: string;
};

export type NewsLandingProps = {
	content: BlogLandingStaticContent;
};

const BreadcrumbContainer = styled.div`
	padding-top: 52px;
	position: relative;
	z-index: 1;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-top: 28px;
			}
		`;
	}}
`;

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	margin: 0px;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const BigSpacer = styled.div`
	display: block;
	width: 100%;
	height: 70px;
`;

const LoadMore = styled.div`
	display: block;
	margin: 0 auto;
	cursor: pointer;
	width: 210px;
	box-sizing: border-box;
	line-height: 36px;
	text-align: center;
	height: 40px;
	border-radius: 24px;
`;

const HeroContainer = styled.div`
	display: block;
	width: 100%;
	padding-top: 85px;
	box-sizing: border-box;
	height: auto;
	margin-bottom: 50px;
	position: relative;
`;

const HeroImage = styled.div`
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const PoweredBy = styled.div`
	display: block;
	width: 250px;
	height: 72px;
	position: absolute;
	top: 115px;
	right: 0px;
	text-align: right;
	img {
		display: block;
		margin: 9px 0px 0px auto;
		height: 15px;
		width: auto;
	}
`;

const FirstBlog = styled.div`
	display: block;
	width: 100%;
	height: 400px;
`;

const FirstBlogImage = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 660px;
	height: 400px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	margin-right: 140px;
`;

const FirstBlogTitle = styled.div`
	display: inline-block;
	width: calc(100% - 800px);
	position: relative;
	height: 400px;
`;

const BlogItem = styled.div`
	display: block;
	width: 100%;
	height: 180px;
`;

const BlogItemImage = styled.div`
	display: inline-block;
	width: 300px;
	margin-right: 100px;
	vertical-align: top;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const BlogTitle = styled.div`
	display: inline-block;
	width: 300px;
	vertical-align: top;
	position: relative;
	height: 180px;
	margin-right: 100px;
`;

const BadgesContainer = styled.div`
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: auto;
`;

const MobileBadgesContainer = styled.div`
	width: 100%;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	height: auto;
`;

const BlogTitleBadge = styled.div`
	display: inline-block;
	white-space: no-wrap;
	margin-right: 5px;
	margin-bottom: 5px;
	height: 34px;
`;

const BlogBlurb = styled.div`
	display: inline-block;
	width: calc(100% - 800px);
	padding-top: 5px;
	box-sizing: border-box;
	height: 180px;
`;

const MobileBlogItem = styled.div`
	display: block;
	width: 100%;
	margin-bottom: 40px;
	height: auto;
`;

const MobileBlogItemImage = styled.div`
	display: block;
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	position: relative;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const MobileBlogTitleBadge = styled.div`
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 5px;
	white-space: no-wrap;
`;

const stayTunedParent = "Blogs-Landing";

const Landing: React.FC<NewsLandingProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();

	const queryData = useStaticQuery(graphql`
		query AllBlogsMetaQuery {
			allContentfulBlog {
				nodes {
					slug
					category
					title
					heroImage {
						file {
							url
						}
					}
					publishDate
					node_locale
					seoDescription
					categories {
						categoryColor
						title
					}
				}
			}
			allContentfulBlogCategory {
				nodes {
					categoryColor
					slug
					title
					listPageImage {
						file {
							url
						}
					}
					node_locale
					ordinal
				}
			}
		}
	`);

	let blogContent: LanguageContent<any> = {
		en: queryData.allContentfulBlog.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "en")
			.sort(
				(a: any, b: any) =>
					new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf()
			),
		fr: queryData.allContentfulBlog.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "fr")
			.sort(
				(a: any, b: any) =>
					new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf()
			),
		es: queryData.allContentfulBlog.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "es")
			.sort(
				(a: any, b: any) =>
					new Date(b.publishDate).valueOf() - new Date(a.publishDate).valueOf()
			),
	};

	let categoryContent: LanguageContent<any> = {
		en: queryData.allContentfulBlogCategory.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "en")
			.sort((a: any, b: any) => a.ordinal - b.ordinal),
		fr: queryData.allContentfulBlogCategory.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "fr")
			.sort((a: any, b: any) => a.ordinal - b.ordinal),
		es: queryData.allContentfulBlogCategory.nodes
			.filter((node: any) => node.node_locale.split("-").shift() === "es")
			.sort((a: any, b: any) => a.ordinal - b.ordinal),
	};

	type StaticContentType = {
		label: string;
		url: string;
		title: string;
		imageUrl: string;
		description: string;
		blogCategories: string;
		backgroundImageUrl: string;
	};
	const StaticContent: LanguageContent<StaticContentType> = {
		en: {
			label: "All Content",
			blogCategories: "Blog Categories",
			title: "Metal Market Intelligence",
			url: "/metal-resources/metal-market-intelligence",
			imageUrl: "/images/metal-resources/allContentThumbnail.jpg",
			description:
				"Which direction are metal prices headed and why? Check out what our professionals have to say about the latest trends.",
			backgroundImageUrl: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		},

		fr: {
			label: "Tout le contenu",
			blogCategories: "Catégories de blog",
			title: "Information sur le marché du métal",
			url: "",
			imageUrl: "/images/metal-resources/allContentThumbnail.jpg",
			description:
				"Dans quelle direction vont les prix des métaux? Et pourquoi? Découvrez ce que nos experts ont à dire sur les dernières tendances.",
			backgroundImageUrl: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		},

		es: {
			label: "Todo el contenido",
			blogCategories: "Categorías de blog",
			title: "Información del mercado de metales:",
			url: "",
			imageUrl: "/images/metal-resources/allContentThumbnail.jpg",
			description:
				"¿A dónde se dirigen los precios de metal y por qué? Vea lo que nuestros expertos han dicho acerca de las últimas tendencias.",
			backgroundImageUrl: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		},
	};

	const [slicePoint, setSlicePoint] = React.useState<number>(8);
	const [mobileSlicePoint, setMobileSlicePoint] = React.useState<number>(8);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="205px" type="tertiary">
					<HeroImage
						css={css`
							background-image: url(${StaticContent[language].backgroundImageUrl});
							background-position: center center;
							background-size: cover;
							background-repeat: no-repeat;
							height: 410px;
							top: 0px;
							left: 0px;
						`}
					/>
					<div
						css={css`
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 410px;
							background-color: rgba(0, 40, 85, 0.2);
							z-index: 1;
						`}
					>
						<div
							css={css`
								display: block;
								width: 100%;
								max-width: 1160px;
								margin: 0 auto;
								height: auto;
							`}
						>
							<BreadcrumbContainer
								theme={theme}
								css={css`
									padding-top: 36px;
								`}
							>
								<Breadcrumb type="tertiary" size="xs" />
							</BreadcrumbContainer>
							<HeroContainer
								css={css`
									margin-bottom: 50px;
									padding-top: 85px;
								`}
							>
								<Typography
									variant="h3"
									type="tertiary"
									css={css`
										margin-top: 0px;
										letter-spacing: -1px;
										margin-bottom: 15px;
									`}
								>
									{content.title}
								</Typography>
								<PoweredBy>
									<Typography
										variant="h3"
										type="tertiary"
										css={css`
											font-size: 20px;
											line-height: 24px;
											letter-spacing: -1px;
										`}
									>
										{content.subTitle[0] ?? ""}
									</Typography>
									<Typography
										variant="h3"
										type="tertiary"
										css={css`
											font-size: 20px;
											line-height: 24px;
											letter-spacing: -1px;
										`}
									>
										{content.subTitle[1] ?? ""}
									</Typography>
									<img src={content.subTitleLogo} />
								</PoweredBy>

								<Typography
									variant="h1"
									type="tertiary"
									css={css`
										margin-bottom: 20px;
										font-size: 75px;
										max-width: calc(100% - 240px);
										letter-spacing: -2px;
										@media (max-width: ${theme.breakpoints.xl}) {
											font-size: 56px;
										}
									`}
									weight="bolder"
								>
									{StaticContent[language].title}
								</Typography>
								<Typography
									variant="div"
									css={css`
										margin: 0px;
										white-space: pre-line;
									`}
									color={theme.colors.tertiary.header}
									size="sm"
								>
									{StaticContent[language].description}
								</Typography>
							</HeroContainer>
							<Spacer
								css={css`
									height: 47px;
								`}
							/>
						</div>
					</div>
				</ContentSection>
				<ContentSection type="secondary" vPadding="40px">
					<Typography variant="h4" type="secondary">
						{StaticContent[language].blogCategories}
					</Typography>
					<Spacer
						css={css`
							height: 40px;
						`}
					/>
					{categoryContent[language] && categoryContent[language].length > 0 && (
						<Flex
							direction="row"
							justifyContent="space-between"
							css={css`
								flex-wrap: wrap;
								gap: 20px;
							`}
						>
							{categoryContent[language].map((category: any, index: number) => {
								return (
									<Link
										to={
											"/metal-resources/metal-market-intelligence/" +
											category.slug
										}
										key={index}
									>
										<FlexItem
											css={css`
												width: 200px;
												height: 100px;
												background-image: url(${category?.listPageImage
													?.file?.url});
												background-size: cover;
												position: relative;
												border-radius: 2px;
												margin-bottom: 40px;
												&:hover {
													.overlay {
														visibility: visible !important;
														opacity: 0.5 !important;
													}
												}
											`}
										>
											<div
												className="overlay"
												css={css`
													width: 200px;
													height: 100px;
													background-color: rgb(0, 0, 0);
													opacity: 0;
													visibility: hidden;
													position: absolute;
													top: 0;
													left: 0;
													transition: 0.15s visibility linear,
														0.15s opacity linear;
												`}
											/>
											<div
												css={css`
													display: block;
													position: absolute;
													height: 40px;
													width: 100%;
													top: calc(100% - 20px);
													left: 0px;
												`}
											>
												<Badge
													color={category.categoryColor}
													type="tertiary"
												>
													{category.title}
												</Badge>
											</div>
										</FlexItem>
									</Link>
								);
							})}
						</Flex>
					)}
					<Spacer
						css={css`
							height: 20px;
						`}
					/>
				</ContentSection>
				<ContentSection
					vPadding="0px"
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.secondary.background,
								percentage: "50%",
							},
							{
								color: theme.colors.primary.background,
								percentage: "50%",
							},
						],
					}}
				>
					<CupOJoe />
				</ContentSection>
				<ContentSection type="primary" vPadding="35px">
					<Spacer
						css={css`
							height: 65px;
						`}
					/>
					{blogContent[language].slice(0, 1).map((blog: any, index: number) => {
						const url = blog?.heroImage?.file?.url;
						const heroImg = url ? url + "?fm=jpg&fit=fill&w=660&h=400" : "";
						return (
							<React.Fragment key={index}>
								<FirstBlog>
									<FirstBlogImage
										css={css`
											background-image: url(${heroImg});
										`}
									/>

									<FirstBlogTitle>
										<Link
											to={
												"/metal-resources/metal-market-intelligence/" +
												blog?.slug
											}
										>
											<Typography
												variant="h2"
												type="primary"
												css={css`
													margin: 0px 0px 48px 0px;
												`}
											>
												{blog?.title}
											</Typography>
										</Link>
										<Typography variant="div" size="md" type="primary">
											{blog?.seoDescription}
										</Typography>
										<BadgesContainer>
											{blog.categories &&
												blog.categories.length > 0 &&
												blog.categories.map(
													(category: any, index: number) => {
														return (
															<BlogTitleBadge key={index}>
																<Badge
																	type="primary"
																	color={category.categoryColor}
																>
																	{category.title}
																</Badge>
															</BlogTitleBadge>
														);
													}
												)}
										</BadgesContainer>
									</FirstBlogTitle>
								</FirstBlog>
								<Divider
									css={css`
										border-bottom: 1px solid ${theme.colors.primary.darkerGray};
										opacity: 0.1;
										margin-top: 68px;
										margin-bottom: 39px;
									`}
								/>
							</React.Fragment>
						);
					})}
					{blogContent[language].slice(1, 5).map((blog: any, index: number) => {
						const url = blog?.heroImage?.file?.url;
						const heroImg = url ? url + "?fm=jpg&fit=fill&w=300&h=180" : "";
						return (
							<React.Fragment key={index}>
								<BlogItem>
									<BlogItemImage
										css={css`
											background-image: url(${heroImg});
										`}
									/>
									<BlogTitle>
										<Link
											to={
												"/metal-resources/metal-market-intelligence/" +
												blog?.slug
											}
										>
											<Typography
												variant="h3"
												type="primary"
												css={css`
													margin: 0px;
												`}
											>
												{blog?.title}
											</Typography>
										</Link>
										<BadgesContainer>
											{blog.categories &&
												blog.categories.length > 0 &&
												blog.categories.map(
													(category: any, index: number) => {
														return (
															<BlogTitleBadge key={index}>
																<Badge
																	type="primary"
																	color={category.categoryColor}
																>
																	{category.title}
																</Badge>
															</BlogTitleBadge>
														);
													}
												)}
										</BadgesContainer>
									</BlogTitle>
									<BlogBlurb>
										<Typography variant="div" size="md" type="primary">
											{blog?.seoDescription}
										</Typography>
									</BlogBlurb>
								</BlogItem>
								<Divider
									css={css`
										border-bottom: 1px solid ${theme.colors.primary.darkerGray};
										opacity: 0.1;
										margin-top: 40px;
										margin-bottom: 39px;
									`}
								/>
							</React.Fragment>
						);
					})}
				</ContentSection>
				<StayTunedComponent parent={stayTunedParent} parentCategory={""} />
				<ContentSection type="primary" vPadding="80px">
					<MaterialMovers />
					{blogContent[language].slice(5, slicePoint).length > 0 ? (
						<Divider
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.darkerGray};
								opacity: 0.1;
								margin-top: 84px;
								margin-bottom: 39px;
							`}
						/>
					) : (
						<></>
					)}

					{blogContent[language].slice(5, slicePoint).map((blog: any, index: number) => {
						const url = blog?.heroImage?.file?.url;
						const heroImg = url ? url + "?fm=jpg&fit=fill&w=300&h=180" : "";
						return (
							<React.Fragment key={index}>
								<BlogItem>
									<BlogItemImage
										css={css`
											background-image: url(${heroImg});
										`}
									/>
									<BlogTitle>
										<Link
											to={
												"/metal-resources/metal-market-intelligence/" +
												blog?.slug
											}
										>
											<Typography
												variant="h3"
												type="primary"
												css={css`
													margin: 0px;
												`}
											>
												{blog?.title}
											</Typography>
										</Link>
										<BadgesContainer>
											{blog.categories &&
												blog.categories.length > 0 &&
												blog.categories.map(
													(category: any, index: number) => {
														return (
															<BlogTitleBadge key={index}>
																<Badge
																	type="primary"
																	color={category.categoryColor}
																>
																	{category.title}
																</Badge>
															</BlogTitleBadge>
														);
													}
												)}
										</BadgesContainer>
									</BlogTitle>
									<BlogBlurb>
										<Typography variant="div" size="md" type="primary">
											{blog?.seoDescription}
										</Typography>
									</BlogBlurb>
								</BlogItem>
								<Divider
									css={css`
										border-bottom: 1px solid ${theme.colors.primary.darkerGray};
										opacity: 0.1;
										margin-top: 40px;
										margin-bottom: 39px;
									`}
								/>
							</React.Fragment>
						);
					})}
					{blogContent[language].length - 1 > slicePoint ? (
						<LoadMore
							css={css`
								border: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setSlicePoint(slicePoint + 4);
							}}
						>
							<Typography
								variant="span"
								color={theme.colors.primary.darkGray}
								weight="bold"
								size="sm"
							>
								{content.loadMore}
							</Typography>
						</LoadMore>
					) : (
						<></>
					)}
					<BigSpacer />
					<Spacer />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="195px" type="tertiary">
					<HeroImage
						css={css`
							background-image: url(${StaticContent[language].backgroundImageUrl});
							background-position: center center;
							background-size: cover;
							background-repeat: no-repeat;
							height: 390px;
							top: 0px;
							left: 0px;
						`}
					/>
					<div
						css={css`
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;

							height: 390px;
							background-color: rgba(0, 40, 85, 0.5);
							z-index: 1;
						`}
					>
						<div
							css={css`
								display: block;
								width: 100%;
								padding-left: 20px;
								padding-right: 20px;
								box-sizing: border-box;
								height: auto;
							`}
						>
							<BreadcrumbContainer
								theme={theme}
								css={css`
									padding-top: 28px;
								`}
							>
								<Breadcrumb type="tertiary" size="xs" />
							</BreadcrumbContainer>

							<Typography
								variant="h1"
								type="tertiary"
								css={css`
									margin-bottom: 20px;
									margin-top: 10px;
									line-height: 40px !important;
									letter-spacing: -2px;
								`}
								weight="bolder"
							>
								{StaticContent[language].title}
							</Typography>
							<Typography
								variant="div"
								css={css`
									margin: 0px;
									white-space: pre-line;
								`}
								color={theme.colors.tertiary.header}
								size="sm"
							>
								{StaticContent[language].description}
							</Typography>
							<Spacer
								css={css`
									height: 66px;
								`}
							/>
							<Typography
								variant="h3"
								type="tertiary"
								css={css`
									font-size: 20px !important;
									line-height: 24px !important;
									letter-spacing: -1px;
								`}
							>
								{content.subTitle[0] ?? ""}
							</Typography>
							<Typography
								variant="h3"
								type="tertiary"
								css={css`
									font-size: 20px !important;
									line-height: 24px !important;
									letter-spacing: -1px;
								`}
							>
								{content.subTitle[1] ?? ""}
							</Typography>
							<img
								src={content.subTitleLogo}
								css={css`
									width: 114px;
									margin-top: 4px;
									height: auto;
									display: block;
								`}
								alt="Ryerson"
							/>
						</div>
					</div>
				</ContentSection>
				<ContentSection type="secondary" vPadding="40px">
					<Typography variant="h4" type="secondary">
						{StaticContent[language].blogCategories}
					</Typography>
					<Spacer
						css={css`
							height: 40px;
						`}
					/>
					{categoryContent[language] && categoryContent[language].length > 0 && (
						<Flex
							direction="row"
							justifyContent="center"
							css={css`
								flex-wrap: wrap;
								gap: 10px;
							`}
						>
							{categoryContent[language].map((category: any, index: number) => {
								return (
									<Link
										to={
											"/metal-resources/metal-market-intelligence/" +
											category.slug
										}
										key={index}
									>
										<FlexItem
											css={css`
												width: 170px;
												height: 100px;
												background-image: url(${category?.listPageImage
													?.file?.url});
												background-size: cover;
												position: relative;
												margin-bottom: 10px;
												border-radius: 2px;
											`}
										>
											<div
												css={css`
													display: block;
													position: absolute;
													height: 40px;
													width: 100%;
													top: calc(100% - 20px);
													left: 0px;
												`}
											>
												<Badge
													color={category.categoryColor}
													type="tertiary"
												>
													{category.title}
												</Badge>
											</div>
										</FlexItem>
									</Link>
								);
							})}
						</Flex>
					)}
					<Spacer
						css={css`
							height: 20px;
						`}
					/>
				</ContentSection>
				<ContentSection
					vPadding="0px"
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.secondary.background,
								percentage: "50%",
							},
							{
								color: theme.colors.primary.background,
								percentage: "50%",
							},
						],
					}}
				>
					<CupOJoe />
					<Spacer />
					<Spacer />
				</ContentSection>
				<ContentSection type="primary" hPadding="0px" vPadding="0px">
					<ContentSection type="primary" vPadding="0px">
						<Spacer />
						<Spacer />
						{blogContent[language].slice(0, 5).map((blog: any, index: number) => {
							const url = blog?.heroImage?.file?.url;
							const heroImg = url ? url + "?fm=jpg&fit=fill&w=300&h=180" : "";
							return (
								<Link
									to={"/metal-resources/metal-market-intelligence/" + blog?.slug}
									key={index}
								>
									<MobileBlogItem>
										<MobileBlogItemImage
											css={css`
												background-image: url(${heroImg});
											`}
										></MobileBlogItemImage>
										<Typography
											variant="h3"
											type="primary"
											css={css`
												margin-top: 25px;
												margin-bottom: 10px;
											`}
										>
											{blog?.title}
										</Typography>
										<MobileBadgesContainer>
											{blog.categories &&
												blog.categories.length > 0 &&
												blog.categories.map(
													(category: any, index: number) => {
														return (
															<MobileBlogTitleBadge key={index}>
																<Badge
																	type="primary"
																	size="sm"
																	color={category.categoryColor}
																>
																	{category.title}
																</Badge>
															</MobileBlogTitleBadge>
														);
													}
												)}
										</MobileBadgesContainer>
										<Typography variant="div" size="md" type="primary">
											{blog?.seoDescription}
										</Typography>
									</MobileBlogItem>
								</Link>
							);
						})}
					</ContentSection>
					<Spacer />
					<StayTunedComponent parent={stayTunedParent} parentCategory={""} />
					<MaterialMovers />
					<ContentSection type="primary" vPadding="48px">
						{blogContent[language]
							.slice(5, mobileSlicePoint)
							.map((blog: any, index: number) => {
								const url = blog?.heroImage?.file?.url;
								const heroImg = url ? url + "?fm=jpg&fit=fill&w=300&h=180" : "";
								return (
									<Link
										to={
											"/metal-resources/metal-market-intelligence/" +
											blog?.slug
										}
										key={index}
									>
										<MobileBlogItem>
											<MobileBlogItemImage
												css={css`
													background-image: url(${heroImg});
												`}
											></MobileBlogItemImage>
											<Typography
												variant="h3"
												type="primary"
												css={css`
													margin-top: 25px;
													margin-bottom: 10px;
												`}
											>
												{blog?.title}
											</Typography>
											<MobileBadgesContainer>
												{blog.categories &&
													blog.categories.length > 0 &&
													blog.categories.map(
														(category: any, index: number) => {
															return (
																<MobileBlogTitleBadge key={index}>
																	<Badge
																		type="primary"
																		size="sm"
																		color={
																			category.categoryColor
																		}
																	>
																		{category.title}
																	</Badge>
																</MobileBlogTitleBadge>
															);
														}
													)}
											</MobileBadgesContainer>
											<Typography variant="div" size="md" type="primary">
												{blog?.seoDescription}
											</Typography>
										</MobileBlogItem>
									</Link>
								);
							})}
						{blogContent[language].length - 1 > mobileSlicePoint ? (
							<LoadMore
								css={css`
									border: 1px solid ${theme.colors.primary.gray};
								`}
								onClick={() => {
									setMobileSlicePoint(mobileSlicePoint + 4);
								}}
							>
								<Typography
									variant="span"
									color={theme.colors.primary.darkGray}
									weight="bold"
									size="sm"
								>
									{content.loadMore}
								</Typography>
							</LoadMore>
						) : (
							<></>
						)}
					</ContentSection>
				</ContentSection>
			</Media>
		</>
	);
};

export default Landing;
